import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    alias: '/plansa-usoara',
    name: 'Planșă ușoară',
    component: Home,
    props: {cells: 8, size: 26, planes: 3, color:'#FF0937', largeWings: false}
  },
  {
    path: '/plansa-medie',
    name: 'Planșă medie',
    component: Home,
    props: {cells: 10, size: 23, planes: 4, color:'#008EC6', largeWings: false}
  },
  {
    path: '/plansa-dificila',
    name: 'Planșă dificilă',
    component: Home,
    props: {cells: 12, size: 16, planes: 5, color:'#EB28B0', largeWings: true}
  },
  {
    path: '/plansa-imposibila',
    name: 'Planșă imposibilă',
    component: Home,
    props: {cells: 14, size: 13, planes: 8, color:'#4C1FDD', largeWings: true}
  },
  {
    path: '/reguli-de-joc',
    name: 'Reguli de joc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GameRules.vue')
  },
  {
    path: '/intrebari-frecvente',
    name: 'Întrebări frecvente',
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQ.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
