import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cellsX:Number,
    cellsY:Number,
    cellSize:Number,
    planeCount:Number,
    fillColor: String
  },
  mutations: {
    setCellsX(state, count){
      state.cellsX = count
    },
    setCellsY(state, count){
      state.cellsY = count
    },
    setCellSize(state, size){
      state.cellSize = size
    },
    setPlaneCount(state, count){
      state.planeCount = count
    },
    setFillColor(state, color){
      state.fillColor = color
    }
  },
  actions: {
    CellsX(context, count){      
      context.commit('setCellsX', count)
    },
    CellsY(context, count){      
      context.commit('setCellsY', count)
    },
    CellSize(context, size){
      context.commit('setCellSize', size)
    },
    PlaneCount(context, count){
      context.commit('setPlaneCount', count)
    },
    FillColor(context, color){
      context.commit('setFillColor', color)
    }
  },
  modules: {
  }
})
