<template>
  <div class="home" style="margin: 0 auto">
      <PlaneSheet
        sheet-title="Avioanele"
        logo-title="www.avioanele.ro"
        logo-subtitle="Un joc pe hârtie, din copilărie!"
        v-bind:sheet-code=sheetCode
        sheet-code-subtitle="Codul acestei planșe trebuie să fie identic cu codul de pe planșa adversarului."
        v-bind:cell-count=cellCount
        v-bind:cell-size=cellSize
        v-bind:plane-count=planeCount
        v-bind:fill-color=fillColor
        v-bind:long-wings=longWings                
        v-on:no-planes="onNoPlanes"
        v-on:no-backend="onNoBackend"
        v-bind:refresh=refresh        
      />
    <v-snackbar v-model="error" :timeout="8000">
      {{errorMessage}}
      <v-btn color="blue" text @click="error = false">Închide</v-btn>
    </v-snackbar>

    <v-speed-dial v-model="fab" top right direction="bottom" open-on-hover transition="slide-y-reverse-transition" class="hidden-print-only">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>airplanemode_active</v-icon>
        </v-btn>
      </template>
      <v-btn color="red" fab dark small @click="Print">
        <v-icon>print</v-icon>
      </v-btn>
      <v-btn color="green" fab dark small @click.stop="refresh = !refresh">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>      
      <v-btn color="indigo" fab dark small @click="dialog = !dialog">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </v-speed-dial>

    <!-- Start of settings dialog -->
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <v-card-title class="grey darken-2 white--text">Setări</v-card-title>
        <v-container fluid>          
          <v-row class="mx-2 mt-6">
            <!-- Cell count (horizontally and vertically) --> 
            <v-col cols="12">
              <v-slider v-model="dialogData.cellCount" :max="dialogData.maxCellCount" :min="dialogData.minCellCount" hide-details thumb-label="always" prepend-icon="border_outer" label="Număr de celule"></v-slider>
            </v-col>            
            <!-- Plane count -->
            <v-col cols="9">
              <v-slider v-model="dialogData.planeCount" :max="dialogData.maxPlanes" :min="dialogData.minPlanes" hide-details thumb-label="always" prepend-icon="airplanemode_active" label="Număr de avioane"></v-slider>
            </v-col>          
            <!-- Plane type -->           
            <v-col cols="3">
              <v-switch v-model="dialogData.longWings" class="mt-1 pt-0" prepend-icon="flight_takeoff" label="Aripi lungi" ></v-switch>
            </v-col>
            <!-- Cell size --> 
            <v-col cols="12">
              <v-slider v-model="dialogData.cellSize" :max="dialogData.maxCellSize" :min="dialogData.minCellSize" hide-details thumb-label="always" prepend-icon="swap_horiz" label="Dimensiune celulă"></v-slider>
            </v-col>                    
            <!-- Plane color -->           
            <v-col cols="6">
              <v-row align="center" justify="center">
                <p>Culoarea avioanelor</p>
              </v-row>
              <v-row align="center" justify="center">
                <v-color-picker v-model="dialogData.fillColor"></v-color-picker>
              </v-row>
            </v-col>
            <!-- Sheet code -->           
            <v-col cols="6">
              <v-row align="center" justify="center">
                <p>Codul planșei tale:</p>
              </v-row>
              <v-row align="center" justify="center">
                <p class="display-4 font-weight-black">{{SheetCode}}</p>
              </v-row>
              <v-row align="center" justify="center">
                <p class="font-italic font-weight-light">Asigură-te că adversarul tău a generat o planșă cu aceleași caracteristici. Codul afișat trebuie să fie identic cu codul de pe planșa adversarului.</p>
              </v-row>
            </v-col>
          </v-row> 
        </v-container>
        <v-card-actions>
          <v-btn text color="secondary" @click="Easy">Ușor</v-btn>
          <v-btn text color="secondary" @click="Medium">Mediu</v-btn>
          <v-btn text color="secondary" @click="Hard">Dificil</v-btn>
          <v-spacer />
          <v-btn text @click="dialog=false">Renunță</v-btn>
          <v-btn text color="primary" @click="Save">Salvează</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End of settings dialog -->
</div>
</template>

<script>
// @ is an alias to /src
import PlaneSheet from '@/components/PlaneSheet.vue'

export default {
  name: 'Home',
  components: {    
    PlaneSheet
  },
  data: () => ({    
    cellCount: 0,
    cellSize:0,
    planeCount: 0,
    longWings: false,
    fillColor: '',
    sheetCode: '',
    // Dummy boolean toggled when refreshing sheet, in order to trigger an update
    refresh: false,
    // TRUE if the dialog is open
    dialog: false,
    // TRUE if the floating action button is open
    fab: false,
    // Error conditions
    error: false,
    errorMessage: '',
    // Data coming from the modal dialog (will be copied over the current data at dialog exit)
    // This intermediate step is necessary to avoid repeated calls to backend when the user updates the dialog
    dialogData: {
      minCellCount: 8,
      maxCellCount: 14,
      minCellSize: 1,
      maxCellSize: 25,
      minPlanes: 1,
      maxPlanes: 10,
      cellCount: 0,
      cellSize: 0,
      planeCount: 0,
      longWings: false,
      fillColor: '',
    }
  }),
  props: {
    cells:Number,       // Number of cells, horizontally and vertically (from 8 to 14)
    size:Number,        // Cell size, from 1 to 25
    planes:Number,      // Number of planes, from 1 to 10
    color: String,      // Fill color (RGB)
    largeWings: Boolean // TRUE if the planes have long wings
  },
  created() {
    // At the very beginning, copy over the prop values into local data and dialog data
    this.cellCount = this.cells
    this.dialogData.cellCount = this.cells
    this.cellSize = this.size
    this.dialogData.cellSize = this.size    
    this.planeCount = this.planes
    this.dialogData.planeCount = this.planes
    this.fillColor = this.color
    this.dialogData.fillColor = this.color
    this.longWings = this.largeWings
    this.dialogData.longWings = this.largeWings
    this.sheetCode = this.SheetCode
  }, 
  computed: {
    SheetCode: function() {                  
      var t1 = ((this.dialogData.cellCount-8) & 0x07) << 0      
      var t2 = (this.dialogData.planeCount & 0x0F) << 3      
      var t3 = ((this.dialogData.longWings ? 1 : 0) & 0x01) << 7      
      var n = t1 | t2 | t3
      n = n + 202 // Offset for the code to look "good" in base 22 (i.e. from AA onwards)    
      return n.toString(22).toUpperCase()
    }
  },
  methods: {
    Print: function() {
      window.print()
    },
    Easy: function(){      
      this.dialogData.cellCount = 8
      this.dialogData.cellSize = 26
      this.dialogData.planeCount = 3
      this.dialogData.longWings = false
      this.dialogData.fillColor = '#FF0937'     
    },
    Medium: function(){      
      this.dialogData.cellCount = 10
      this.dialogData.cellSize = 23
      this.dialogData.planeCount = 4
      this.dialogData.longWings = false
      this.dialogData.fillColor = '#008EC6'     
    },
    Hard: function(){      
      this.dialogData.cellCount = 12
      this.dialogData.cellSize = 16
      this.dialogData.planeCount = 5
      this.dialogData.longWings = true
      this.dialogData.fillColor = '#EB28B0'     
    },
    Save: function(){      
      this.cellCount = this.dialogData.cellCount,
      this.cellSize = this.dialogData.cellSize,
      this.planeCount = this.dialogData.planeCount,
      this.longWings = this.dialogData.longWings,
      this.fillColor = this.dialogData.fillColor,
      this.sheetCode = this.SheetCode
      // Close dialog
      this.dialog = false
    },
    onNoPlanes: function() {      
      this.errorMessage = "Ai încercat să plasezi prea multe avioane pentru această grilă."
      this.error = true
      // Open the dialog
      this.dialog = true
    },
    onNoBackend: function() {
      this.errorMessage = "Eroare internă. Generatorul de planșe nu este momentan disponibil."
      this.error = true
    }
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}
</style>