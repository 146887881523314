<template>
<div style="text-align: center;">
    <!-- DIV to display SVG document -->    
    <div id="svg_area" ref="capture" v-if="planesPlacedOnSheet" v-html="sheetSVG"/>
    <a v-if="planesPlacedOnSheet" :href="sheetPNG" download="plansa-avioanele-ro.png">Descarcă planșa (PNG)</a>
</div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
 name: 'PlaneSheet',
 data () {
    return {      
      sheetSVG: null,
      sheetPNG: null,
      sheetFilename: null,
      planesPlacedOnSheet: false      
    }
  },
  props: {
    sheetTitle: String,
    logoTitle: String,
    logoSubtitle: String,
    sheetCode: String,
    sheetCodeSubtitle: String,
    cellCount:Number,
    cellSize:Number,
    planeCount:Number,
    fillColor: String,
    longWings: Boolean,    
    // Dummy boolean used to trigger component update when toggled
    refresh: Boolean
  },
  methods: {
    connectToBackend: function () {      
      this.$axios({
          method: 'post',
          url: process.env.VUE_APP_BACKENDURL + '/generate-sheet',
          data : {
              'SheetTitle': this.sheetTitle,
              'LogoTitle': this.logoTitle,
              'LogoSubtitle': this.logoSubtitle,
              'SheetCode': this.sheetCode,
              'SheetCodeSubtitle': this.sheetCodeSubtitle,
              'CellsX':     this.cellCount,
              'CellsY':     this.cellCount,
              'CellSize':   this.cellSize,
              'PlaneCount': this.planeCount,
              'FillColor':  this.fillColor,
              'LongWings':  this.longWings
          }
      })
    .then(response => {
      // Response received from backend      
      this.planesPlacedOnSheet = response.data.PlanesPlacedOnSheet
      if(this.planesPlacedOnSheet) {
        // Save the actual SVG contents       
        this.sheetSVG = response.data.SheetSVG;
      } else {
        this.$ga.event('sheet', 'call backend', 'no-planes', 1)
        // Signal the parent component that the planes could not pe placed on the sheet
        this.$emit('no-planes')        
      }      
    })
    .catch(() => {
      this.$ga.event('sheet', 'call backend', 'no-backend', 1)
      // Response not received from backend      
      this.$emit('no-backend')
    })
    .finally(() => {})
    }    
  },
  mounted() {
    var label = "Generating sheet [" + this.sheetCode + "]: number of cells: " + this.cellCount + ", cell size: " + this.cellSize + ", number of planes: " + this.planeCount + ", plane type: " + this.longWings;
    this.$ga.event('sheet', 'generation', label, 1)
    this.connectToBackend()
  }, 
  updated: function(){
    // When the SVG is updated, generate PNG from the SVG contents
    html2canvas(this.$refs.capture).then(canvas => {
      this.sheetPNG = canvas.toDataURL();      
    });
  }, 
  watch: {
    $props: {
      handler() {        
        console.log('Settings changed, calling backend again')        
        this.$ga.event('sheet', 'call backend', 'settings changed', 1)
        this.$forceUpdate()
        this.connectToBackend()
      },
    deep: true,
    immediate: false,
    }
  }
}
</script>

<style>
#svg_area svg {
  width: 98vw;
  height: 94vh;  
}
</style>